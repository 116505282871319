const path = (root = '', path = '', param = '') => `${root}${path}${param}`;

export const removeFirstSlash = (path) => {
  const [_, ...rest] = path.split('/');
  return rest.join('/');
};

export const ROOT_ROUTE = '/';
export const AUTH_ROOT = '/auth';
export const DASHBOARD_ROOT = '/dashboard';

export const AUTH_ROUTES = {
  login: path(AUTH_ROOT, '/login'),
  error: path(AUTH_ROOT, '/error'),
};

export const DASHBOARD_ROUTES = {
  home: path(DASHBOARD_ROOT, '/'),
  bookings: {
    all: path(DASHBOARD_ROOT, '/bookings'),
    single: ({id}) => path(DASHBOARD_ROOT, '/bookings', `/${id}`),
  },
  cities: {
    all: path(DASHBOARD_ROOT, '/cities'),
    single: ({id}) => path(DASHBOARD_ROOT, '/cities', `/${id}`),
  },
  feedbacks: `${DASHBOARD_ROOT}/feedbacks`,
  hotels: {
    all: path(DASHBOARD_ROOT, '/hotels'),
    single: ({id}) => path(DASHBOARD_ROOT, '/hotels', `/${id}`),
  },
  products: {
    all: path(DASHBOARD_ROOT, '/products'),
    single: ({id}) => path(DASHBOARD_ROOT, '/products', `/${id}`),
  },
  regions: {
    all: path(DASHBOARD_ROOT, '/regions'),
    single: ({id}) => path(DASHBOARD_ROOT, '/regions', `/${id}`),
  },
  ferries: {
    all: path(DASHBOARD_ROOT, '/ferries'),
    single: ({id}) => path(DASHBOARD_ROOT, '/ferries', `/${id}`),
  },
  agents: {
    all: path(DASHBOARD_ROOT, '/agents'),
    single: ({id}) => path(DASHBOARD_ROOT, '/agents', `/${id}`),
  },
  flags: {
    all: path(DASHBOARD_ROOT, '/flags'),
    single: ({id}) => path(DASHBOARD_ROOT, '/flags', `/${id}`),
  },
  guides: {
    all: path(DASHBOARD_ROOT, '/guides'),
    single: ({id}) => path(DASHBOARD_ROOT, '/guides', `/${id}`),
  },
};
